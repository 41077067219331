<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">申請管理 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flex-wrap">
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請者名</div>
            <div>
              <b-form-input
                @input="setFilter('name', $event)"
                :value="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                :value="search.year"
                @input="setFilter('year', $event)"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">すべて</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成種別</div>
            <div>
              <b-form-select
                @input="setFilter('series', $event)"
                :value="search.series"
                :options="apptypeList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請番号</div>
            <div>
              <b-form-input
                @input="setFilter('code', $event)"
                :value="search.code"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock">
            <div>ステータス</div>
            <div>
              <b-form-select
                @input="setFilter('status', $event)"
                :value="search.status"
                :options="statusList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddleLong inlineBlock searchSpace">
            <div>所属機関(大学、研究機関等)</div>
            <div>
              <b-form-input
                @input="setFilter('institution_name', $event)"
                :value="search.institution_name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>大区分</div>
            <div>
              <b-form-select
                @input="setFilter('division', $event)"
                :value="search.division"
                :options="divisionOptions">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>キーワード</div>
            <div>
              <b-form-input
                @input="setFilter('keyword', $event)"
                :value="search.keyword"
                placeholder="入力してください。"/>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsAppTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          @sort-changed="onSortChanged"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1">全選択/全解除</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 link" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 link" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 link" @click="allSelect">全選択</p>
                <p class="mb-0 link" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedAppIdList($event, row.item.id)"
                :checked="selectedAppIdList"
                :value="row.item.id"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="'/cms/applications/edit/'+row.item.id">
              編集
            </b-link>
          </template>
        </b-table>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="selectedAppIdList.length < 1"
        @click="control(status['RECEIPT'])"
        >一括受領</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canExportCsv"
        @click="exportCsv()"
        >CSV出力</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canExportPdf"
        @click="exportPdf()"
        >PDF出力</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-2"
        :disabled="selectedAppIdList.length < 1"
        to="/cms/applications/mail">メール送信</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
import CONST_OPTIONS from '@/constants/options';
import download from '@/modules/download';

export default {
  name: 'CmsApplicationsList',
  data() {
    return {
      status: CONST_STATUS.APP_STATUS,
      statusList: CONST_STATUS.CMS_APP_STATUS_OPTION_4_LIST,
      dbAppList: [],
      header: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'year', label: '年度', sortable: true },
        { key: 'apptype', label: '助成種別', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'statusText', label: 'ステータス', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      seriesList: [],
      yearList: [],
      isInit: true,
      oldPage: 1,
      groupList: [],
      modalGroupSelected: '',
      sortBy: null,
      sortDesc: false,
      tableShowData: [],
    };
  },
  methods: {
    onSortChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.setSortedData();
    },
    setSortedData() {
      if (this.sortBy) {
        const sortedData = this.tableShowData.slice().sort((a, b) => {
          const aValue = a[this.sortBy];
          const bValue = b[this.sortBy];
          let result;

          if (aValue === null && bValue === null) {
            result = 0;
          } else if (aValue === null) {
            result = 1; // nullを常に最後に
          } else if (bValue === null) {
            result = -1; // nullを常に最後に
          } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            result = aValue - bValue;
          } else {
            result = aValue.toString().localeCompare(bValue.toString(), undefined, { numeric: true, sensitivity: 'base' });
          }
          return this.sortDesc ? -result : result;
        });
        this.$store.commit('cmsApplicationSearch/setFilterdIdList', sortedData);
      }
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsApplicationSearch/setFilter', param);
    },
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const apptypeSort = search.series === '' || lineData.seriesId === search.series;
      // const yearSort = search.year === '' || lineData.year === search.year;
      const codeSort = search.code === '' || lineData.code.includes(search.code);
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      const okInstitution = search.institution_name === '' || lineData.institutionName.includes(search.institution_name);
      const okDivision = search.division === '' || lineData.division === search.division;
      const okKeyword = search.keyword === '' || lineData.keyword.includes(search.keyword);
      return nameSort && apptypeSort && codeSort
        && statusSort && okInstitution && okDivision && okKeyword;
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsApplicationSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsApplicationSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsApplicationSearch/setCurrentPage', 1);
      }
      this.$store.commit('cmsApplicationSearch/initSelectedAppid');
      // 編集ページ内での次の申請、前の申請に移動するためのIDリストの保存
      this.$store.commit('cmsApplicationSearch/setFilterdIdList', filteredItems);
      this.tableShowData = filteredItems;
      this.setSortedData();
    },
    async initFetch(year) {
      const param = { year };
      const promiseFuncs = [api.send('/api/cms/applications/list', param)];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      this.dbAppList = responses[0].data.list;
      this.seriesList = responses[0].data.seriesList;
      this.yearList = responses[0].data.yearList;
      this.groupList = responses[0].data.groupList;
      // 年度が選択されていなければ、最新の年度を選択状態にする
      if (!this.search.year && this.yearList.length > 0) {
        const yearParam = { key: 'year', value: this.yearList[0] };
        this.$store.commit('cmsApplicationSearch/setFilter', yearParam);
      }
      this.$set(this.search, 'year', responses[0].data.selectedYear);
      this.$store.commit('cmsApplicationSearch/setFilterdIdList', this.dbAppList);
    },
    setSeries(apptypeId) {
      const target = this.seriesList.find((series) => {
        return series.apptypeList.find((apptype) => {
          return apptype.id === Number(apptypeId);
        });
      });
      if (target) {
        this.$set(this.search, 'series', target.id);
      }
    },
    async control(status) {
      const count = this.selectedAppIdList.length;
      let msg = `選択中の${count}件の申請のステータスを受領に変更します。`;
      msg += '\nステータスが変更されると、申請者にメールの送信と通知の登録が行われます。\n受領してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        status,
        selectedAppIdList: this.selectedAppIdList,
      };
      const response = await api.send('/api/cms/applications/control', params)
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        return;
      }
      await this.alert('選択した申請を受領しました。', false);
      await this.initFetch(this.search.year);
      this.$store.dispatch('page/offWaiting');
    },
    allSelect() {
      this.$store.commit('cmsApplicationSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsApplicationSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsApplicationSearch/addSelectedAppid', appId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsApplicationSearch/removeSelectedAppid', appId);
      });
    },
    async exportCsv() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        seriesId: this.search.series,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/export/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const series = this.seriesList.find((data) => {
        return data.id === this.search.series;
      });
      const fileName = `${ymd}_${series.name}.csv`;
      download.csvUtf(response.data, fileName);
    },
    async exportPdf() {
      if (!this.canExportPdf) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        seriesId: this.search.series,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/export/pdf', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        // eslint-disable-next-line
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const series = this.seriesList.find((data) => {
        return data.id === this.search.series;
      });
      const fileName = `${ymd}_${series.name}.pdf`;
      download.blob(response.data, fileName);
    },
    updateSelectedAppIdList(event, appId) {
      if (!event) {
        this.$store.commit('cmsApplicationSearch/removeSelectedAppid', appId);
      } else {
        this.$store.commit('cmsApplicationSearch/addSelectedAppid', appId);
      }
    },
    setCurrentPage(page) {
      this.$store.commit('cmsApplicationSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsApplicationSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsApplicationSearch/setTotalRows', value);
    },
    groupSetting() {
      if (!this.canGroupSetting) {
        return;
      }
      this.$refs.groupSettingModal.show();
    },
    async groupSetSave() {
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        selectedGroup: this.modalGroupSelected,
        seriesId: this.search.series,
        year: this.search.year,
      };
      const response = await api.send('/api/cms/applications/groupSave', params)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert(err.response.data.errMsg);
          } else {
            await this.alert('グループの設定に失敗しました。');
          }
          return false;
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('グループの設定が完了しました。');
      this.$refs.groupSettingModal.hide();
      await this.initFetch(this.search.year);
      this.$store.dispatch('page/offWaiting');
    },
  },
  computed: {
    isUseGroup() {
      const { year, series } = this.search;
      if (!year || !series) {
        return false;
      }
      const targetSeries = this.seriesList.find((seriesData) => {
        return seriesData.id === Number(series);
      });
      if (typeof targetSeries === 'undefined') {
        return false;
      }
      const targetAppType = targetSeries.apptypeList.find((appType) => {
        return appType.year === Number(year);
      });
      if (typeof targetAppType === 'undefined') {
        return false;
      }
      return Number(targetAppType.isUseGroup) === 1;
    },
    search() {
      return this.$store.state.cmsApplicationSearch.search;
    },
    currentPage() {
      return this.$store.state.cmsApplicationSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsApplicationSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsApplicationSearch.totalRows;
    },
    appList() {
      const appList = this.dbAppList.map((application) => {
        const divisionText = application.ans_divisions === null ? '' : application.ans_divisions.text;
        return {
          id: application.id,
          apptypeId: application.application_type_id,
          apptype: application.apptype.name,
          name: `${application.user_info.sei} ${application.user_info.mei}`,
          kana: `${application.user_info.kana_sei} ${application.user_info.kana_mei}`,
          year: application.apptype.year,
          code: application.code,
          status: application.status,
          statusText: CONST_STATUS.APP_STATUS_TEXTS[application.status],
          seriesId: application.series.series_id,
          institutionName: application.user_info.institution_name,
          division: divisionText,
          keyword: application.ans_keyword.field_answer_text,
        };
      });
      return appList;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    apptypeList() {
      const appTypeList = this.seriesList.map((series) => {
        return { value: series.id, text: series.name };
      });
      return appTypeList;
    },
    selectedAppIdList() {
      return this.$store.state.cmsApplicationSearch.selectedAppIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsApplicationSearch.filterdIdList;
    },
    canExportCsv() {
      const { year, series } = this.search;
      const number = this.selectedAppIdList.length > 0;
      return number && series && year !== 'all';
    },
    canExportPdf() {
      const { year, series } = this.search;
      const number = this.selectedAppIdList.length > 0;
      return number && series && year !== 'all';
    },
    canGroupSetting() {
      const { year, series } = this.search;
      const number = this.selectedAppIdList.length > 0;
      return number && series && year !== 'all';
    },
    divisionOptions() {
      return CONST_OPTIONS.DIVISIONS;
    },
  },
  watch: {
    'search.year': async function (val) {
      this.$store.dispatch('page/onWaiting');
      await this.initFetch(val);
      this.$store.dispatch('page/offWaiting');
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('userInfo/initInputData');
    this.oldPage = this.currentPage;
    this.$store.commit('cmsApplicationSearch/initSelectedAppid');
    const { year, apptypeId } = this.$route.params;
    // ルーターのparamがなければ検索条件のyearを使う
    const fetchYear = year || this.search.year;
    await this.initFetch(fetchYear);
    this.setSeries(apptypeId);
    this.isInit = false;
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .cmsAppTable thead th:nth-of-type(1) {
    width: 140px !important;
  }
  #groupModal .modal-footer{
    justify-content: center !important;
  }
  #groupModal___BV_modal_outer_ {
    z-index: 999 !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
    margin-bottom: 5px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
  .groupSelectItem {
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .groupSelectItem .title {
    margin-right: 10px;
  }
  .groupSelectItem select {
    font-size: 1.5rem;
  }
</style>
